export default class LeadService {

    getObras() {
        return fetch('demo/data/obras.json').then(res => res.json()).then(d => d.data);
    }


    getProductsSmall() {
        return fetch('demo/data/products-small.json').then(res => res.json()).then(d => d.data);
    }

    getProductsMixed() {
        return fetch('demo/data/products-mixed.json').then(res => res.json()).then(d => d.data);
    }

    getProductsWithOrdersSmall() {
        return fetch('demo/data/products-orders-small.json').then(res => res.json()).then(d => d.data);
    }
}
